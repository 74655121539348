













import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import E from "wangeditor";
@Component({})
export default class Editor extends Vue {
    @Prop({ type: [String, Object], required: false, default: () => ({}) })
    editortxt!: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    editor: any = null;
    editorContent = "";
    imgUrl: string = "";

    loading = false;
    loadingtxt = "拼命加载中";
    isshow = true;

    async uploadImg(img: any) {
        const getopt = this.$api.apiList.uploadImg;
        const opt = getopt();

        let formdata = new FormData();
        formdata.append("file", img);
        let subconunt = 0; //提交次数
        let sub: any = async () => {
            subconunt++;
            let result = await this.$axios({
                method: opt.method,
                url: opt.url,
                headers: opt.headers,
                data: formdata
            })
                .then((res: any) => {
                    if (res.data) {
                        if (res.data.path) {
                            return {
                                status: 0,
                                msg: "上传成功",
                                data: res.data
                            };
                        } else {
                            return {
                                status: 1,
                                msg: "上传失败：服务器未返回图片路径",
                                data: res.data
                            };
                        }
                    } else {
                        return {
                            status: 1,
                            msg: "上传失败：通信成功，但服务器没有返回值"
                        };
                    }
                })
                .catch((err: any) => {
                    if (err.response) {
                        let error: any = err.response.data.errors; //返回值
                        let errKey: any = Object.keys(error); //存放"错误信息key"的数组
                        let errmsg: any = error[errKey[0]][0]; //第一个错误msg
                        return {
                            status: 2,
                            msg: `上传失败:[${err.response.status}]${errmsg}`,
                            data: err.response
                        };
                    } else {
                        if (subconunt < 5) {
                            return false;
                        } else {
                            return {
                                status: 3,
                                msg: `上传失败:[${err.message}]服务器未返回错误信息`,
                                data: err.config
                            };
                        }
                    }
                });

            if (result == false) {
                result = await sub();
                return result;
            } else {
                return result;
            }
        };
        let res = await sub().then((response: any) => {
            return response;
        });
        return res;
    }

    @Watch("formItemOpt")
    optChange(nval: any, oval: any) {
        this.editor.$textContainerElem
            .find(".placeholder")
            .text(nval.placeholder);
    }

    createEditor() {
        this.editor = new E((this.$refs as any).editorElem);
        // this.editor.customConfig.onchange = (html: any) => {
        //     this.editorContent = html;
        // };
        this.editor.config.focus = false; //取消自动获取焦点
        this.editor.config.showLinkImg = false; //是否显示上传网络图片
        this.editor.config.showFullScreen = false; //是否显示全屏按钮
        this.editor.config.uploadImgMaxLength = 1; //限制图片单词上传最大个数
        this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M,限制图片大小
        this.editor.config.zIndex = 98;
        this.editor.config.placeholder = this.formItemOpt.placeholder;
        //    this.editor.i18next = window.i18next

        this.editor.config.menus = [
            "head", // 标题
            "bold", // 粗体
            "fontSize", // 字号
            "fontName", // 字体
            "italic", // 斜体
            "underline", // 下划线
            "strikeThrough", // 删除线
            "foreColor", // 文字颜色
            "backColor", // 背景颜色
            "link", // 插入链接
            "list", // 列表
            "justify", // 对齐方式
            "quote", // 引用
            // "emoticon", // 表情
            "image", // 插入图片
            "table", // 表格
            // "code", // 插入代码
            "undo", // 撤销
            "redo", // 恢复
            "indent", //缩进
            "lineHeight" //行高
        ];

        //自定义提示组件
        this.editor.config.customAlert = (s: any, t: any) => {
            switch (t) {
                case "success":
                    this.$notify.success({ title: "成功", message: s });
                    break;
                case "info":
                    this.$notify.info({ title: "提示", message: s });
                    break;
                case "warning":
                    this.$notify.warning({ title: "警告", message: s });
                    break;
                case "error":
                    this.$notify.error({ title: "错误", message: s });
                    break;
                default:
                    this.$notify.info({ title: "提示", message: s });
                    break;
            }
        };

        //自定义本地图片上传方法
        this.editor.config.customUploadImg = (
            resultFiles: any,
            insertImgFn: any
        ) => {
            // resultFiles 是 input 中选中的文件列表
            // insertImgFn 是获取图片 url 后，插入到编辑器的方法
            this.loading = true;
            this.uploadImg(resultFiles[0]).then((res: any) => {
                this.loading = false;
                if (res.status == 0) {
                    insertImgFn(res.data.path);
                } else {
                }
                this.$notify({
                    title: res.status == 0 ? "成功" : "错误",
                    message: res.msg,
                    type: res.status == 0 ? "success" : "error"
                });
            });

            // 上传图片，返回结果，将图片插入到编辑器中
            // insertImgFn(this.imgUrl);
        };

        //text发生改变时，把值传递给父组件
        this.editor.config.onchange = (html: any) => {
            this.$emit("changetxt", html);
        };

        this.editor.config.onblur = (html: any) => {
            this.$emit("onblur", html);
        };

        this.editor.create(); // 创建富文本实例
        this.editor.txt.html(this.editortxt);
    }

    mounted() {
        this.createEditor();
    }

    beforeDestroy() {
        // 销毁编辑器
        this.editor.destroy();
        this.editor = null;
    }
}
